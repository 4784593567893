* {
  box-sizing: border-box;
}

:root {
  --highlight-color: #cd4545;
  --highlight-color-hover: black;
  --text-color: #222;

  --circle-color: #222;
  --circle-background-color: #fff;
}

a {
  color: var(--highlight-color);
  text-decoration: none;
  font-weight: bold;
  transition: 0.3s color;
}

a:hover {
  color: var(--highlight-color-hover);
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: var(--text-color);
  font-family: 'Josefin Slab', serif;
  font-size: 25px;
  background: url(img/bg.jpg) center no-repeat fixed;
  background-size: cover;
}

h1,
h2,
.subline {
  text-align: center;
  font-weight: normal;
}

h1 {
  margin: 0;
  padding-top: 40px;
  font-size: 110px;
}

h2 {
  font-size: 56px;
  margin: 30px 0 0;
}

img {
  max-width: 100%;
}

.subline {
  font-size: 33px;
  margin: 0;
  line-height: 140%;
}

.content {
  max-width: 1200px;
  display: flex;
  margin: 30px auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-between;
  align-items: center;
}

.content .col {
  width: 30%;
}

.me {
  border-radius: 50%;
  border: 4px solid var(--circle-background-color);
}

.content p {
  text-align: left;
}

.content .col:first-child p {
  text-align: right;
}

.social {
  list-style: none;
  padding: 0;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.social li {
  display: inline-block;
  font-size: 50px;
  padding: 10px 10px;
  line-height: 50px;
}

.social a {
  background: var(--circle-background-color);
  color: var(--circle-color);
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border-radius: 50%;
  transition: 0.3s;
}

.social a svg {
  fill: currentColor;
  height: 1em;
}

.social a:hover {
  color: var(--circle-background-color);
  background: var(--circle-color);
}

.social a img {
  display: block;
  max-width: 60%;
  max-height: 60%;
}

@media screen and (prefers-color-scheme: dark) {
  :root {
    --highlight-color: #c96767;
    --highlight-color-hover: #fff;
    --text-color: #ccc;
    --circle-background-color: #000;
    --circle-color: #ccc;
  }

  body {
    background: #222;
  }
}

@media screen and (max-width: 840px) {
  .content {
    align-items: flex-start;
  }

  .content .col {
    width: 45%;
  }

  .content .picture {
    order: -1;
    width: 60%;
    text-align: center;
  }
}

@media screen and (max-width: 660px) {
  .content .col {
    width: 80%;
  }

  .content .picture {
    width: 60%;
  }

  .content .col p,
  .content .col:first-child p {
    text-align: left;
  }
}

@media screen and (max-width: 400px) {
  .content .col {
    width: 90%;
  }

  h1 {
    font-size: 80px;
  }

  h2 {
    font-size: 30px;
    font-weight: bold;
  }

  .subline {
    font-size: 25px;
  }

  .social li {
    font-size: 30px;
    line-height: 30px;
  }

  .social a {
    padding-top: 2px;
    width: 45px;
    height: 45px;
  }
}
